import 'particles.js';
import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './Header.scss';
import ThemeContext from '../Contexts/ThemeContext';
import Hoverable from './Hoverable';
import scrollToId from '../helpers/scrollToId';

export default React.memo(({ id }: { id: string }) => {

  useEffect(() => {
    (window as any).particlesJS.load("particles-js", "/assets/particles.json", null)
  }, []);

  const { styles } = useContext(ThemeContext);
  const { $secondary, $accent } = styles;

  return <div id={id} className="header" >
    <div className="particles-canvas" id="particles-js" />
    <div className="text opacity-full">
      <span>
        <span className="hello">Hello, </span>
        <span className="end-of-first-line">I'm <span className="highlight" style={{ color: $accent }}>Jordan Pawlett. </span></span>
        <br />
        <span className="second-line">
          Full Stack Software Engineer
        </span>
      </span>
    </div>

    <Hoverable hoverStyle={{ border: `2px solid ${$secondary}`, background: $secondary }} onClick={() => scrollToId('about')}>
      <div className="more-button-wrapper">
        Find out more
        <FontAwesomeIcon className="more-button" size="xs" icon={faArrowRight} />
      </div>
    </Hoverable>
  </div >;
});