import React, { useRef, useContext, useState, useEffect } from "react"
import ThemeContext from '../Contexts/ThemeContext';
import ColourPicker from "./ColourPicker";

import './ThemeEditor.scss';
import Hoverable from "./Hoverable";
import PageTitle from "./PageTitle";

export default function ThemeEditor({ isInView, id }: { isInView: boolean, id: string }) {
  const sectionRef = useRef(null);
  const { styles, setColour } = useContext(ThemeContext);
  const [selectedAttribute, setSelectedAttribute] = useState<string>("$primary");
  const [hasAnimated, setHasAnimated] = useState(window.innerWidth < 600);
  useEffect(() => {
    if (!hasAnimated && isInView) {
      setHasAnimated(true);
    }
  }, [hasAnimated, isInView]);
  function renderContent() {
    if (!hasAnimated) {
      return null;
    }

    return <>
      <PageTitle title="Customise" isOffWhite={true} />
      <div className="selector-container">
        {Object.keys(styles).filter(i => i !== "$grey" && i !== "$secondaryDark").map((key: string, i: number) =>
          // border: `solid 2px ${(styles as any)[key]}`
          <Hoverable key={key} hoverStyle={{ border: `solid 2px ${(styles as any)[key]}`, background: (styles as any)[key], color: 'white' }}>
            <div
              className="attribute-selector"
              style={{
                background: `${selectedAttribute === key ? (styles as any)[key] : 'unset'}`, animationDelay: `${i / 2}s`,
                color: selectedAttribute === key ? 'white' : (styles as any)[key]
              }}
              onClick={() => setSelectedAttribute(key)}
            >
              {key.replace("$", "")}
            </div>
          </Hoverable>
        )}
      </div>
      <div className="picker-container">
        <div className="description">
          Use this section to dynamically alter the pages key colour attributes. <br />
          View the wonders of Javascript!
      </div>
        <ColourPicker onChangeComplete={(color: any) => setColour(selectedAttribute, color.hex)} color={(styles as any)[selectedAttribute]} />
      </div>
    </>
  }

  return <div id={id} className="theme-editor section" ref={sectionRef}>
    {renderContent()}
  </div >
}
